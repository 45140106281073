export const Endpoints = {
  // ** Common
  // ** Auth
  login: "/auth/signin",
  register: "/auth/signup",
  refresh: "/auth/refresh-token",
  logout: "/auth/logout",
  verifyEmail: "/auth/verifyOTP",
  resendOtp: "/auth/resendOTP",
  forgotPassword: "/auth/forgot-password",
  resetPassword: "/auth/reset-password",
  loginWithSSO: "/auth/loginViaAzureSSO",

  // ** Jobs
  getAllJobs: "/job/job_post", // get all jobs that are posted by company ,add id in params to get specific job
  getAppliedJobs: "/job/appliedApplicants", // get all jobs that are applied by user ,add id in params to get specific job {GET} and apply for job {POST}
  getSpecificApplicant: "/job/applicant",
  // countries
  //getAllCountries: "https://restcountries.com/v2/all",
  getAllCountries: "https://countriesnow.space/api/v0.1/countries/capital",
  // states
  getAllStates: "https://countriesnow.space/api/v0.1/countries/states",
  // cities
  getAllCities: "https://countriesnow.space/api/v0.1/countries/state/cities",
  // resume
  // uploadResume: "/seeker_profile/upload_cv",
  uploadResume: "/seeker_profile/upload_cv",

  // profile
  getProfile: "/seeker_profile/profile",
  // create profile
  createProfile: "/seeker_profile/create_whole_profile",

  // JOB CATEGORY {GET} , {POST} , {PATCH} , {DELETE}
  jobCategories: "/job/job_category",
  // skills
  getSkills: "/seeker_profile/skill_set",

  //get profile experience
  getProfileExperience: "/seeker_profile/experience_details",
  //get profile experience
  getProfileEducation: "/seeker_profile/education_details",
  //get profile SKillsets
  getProfileSkillsets: "/seeker_profile/profile",
  //get profile Languages
  getProfileLanguages: "/seeker_profile/seeker_languages",

  companyProfile: "/company",
  companyProfileLocation: "/company/location",

  Skills: "/seeker_profile/skill_set",
  generateJobDescription: "/job/generate_jd_via_ai",

  getApplicantsInfo: "/job/getApplicantsCount",
  oAuthGenerate: "/company/oAuthCredentials",
  changePassword: "/user/changeUserPassword",
  companyLogo: "/company/uploadLogo",

  getAllFeatures: "/feature",
  getAllGroups: "/access/roleGroup",
  listOfGroups: "/access/listOfRoleGroups",
  user: "/user/companyUser",
  getPlans: "/plansAndPricing/listOfPlans",
  getAPILimits: "/company/getApiCallLimit",
  createZoomMeeting: "/integration/zoom/meeting",
  getAllMeetings: "/integration/meeting",
  integrateZoom: "/integration/zoom/config",
  integrateTeams: "/integration/teams/config",
  teamsClientConnect: `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT_ID}/oauth2/v2.0/authorize?client_id=${process.env.REACT_APP_CLIENT_ID}&response_type=code&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&scope=${process.env.REACT_APP_SCOPES}`,
  graphApplicantStatus: "/dashboard/graph/applicant-status",
  graphApplicantReport: "/dashboard/graph/applicant-bar-graph-statistics",
  graphStats: "/dashboard/graph/statistics",
  createTeamsMeeting: "/integration/teams/meeting",
  integrateLinkedIn: "/integration/linkedin/config",
  fetchLinkedInAccessToken: "/integration/linkedin/generateLinkedinToken",
  linkedInJobPost: "/integration/linkedin/createPost",
  generateSkillTest: "/job/generateOnlineSkillTest",
  AuthorizedUris: "/company/authorizedUri",
  createEvaluation: "/forms/evaluation_form",
  scheduleOnBoarding: "/job/applicant/specific",
  submitEvaluationForm: "/onboarding/interviews/specific",
  interviewers: "/user/employees",
  assignedEvaluation: "/onboarding/interviews",
  cancelInterview: "/onboarding",
  rejectApplicant: "/job/applicant/specific",
  uploadOfferLetter: "/onboarding/uploadOfferLetter",
  sendOfferLetter: "/onboarding/applicant",
  profileAssessment: "/assessment/profile",
  createAssessment: "/forms/assessment_question",
  createBulkAssessment: "/forms/assessment_question/bulk-creation",
  submitInterviewTest: "/assessment/technical/applicant/specific",
  integrateFo: "/integration/finance_and_operations/config",
  sendDataToFO: "/job/applicant/post_data_to_fo/specific",
}

// /job/applicant/specific/:id/technicalAssessment
