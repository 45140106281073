import React from 'react';

const Main = () => {
  return (
    <div style={{ color: 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <p>
        © 2025 - {' '}
        <a href="https://www.kaispe.com/" target="_blank">KAISPE</a> - {' '} Terms and Conditions | {' '}
         <a href="https://www.kaispe.com/privacy-policy/" target="_blank">Privacy Policy</a> | {' '}
        <a href="https://cp.kaispe.com/contact" target="_blank">Contact Us</a>
      </p>
    </div>
  );
};

export default Main;
