import * as loginActions from "./actions"

import { LogintoAzure, login, postAzureLogin } from "@src/services/apis"
import { all, call, put, takeEvery } from "redux-saga/effects"

import { LOGIN_TYPES } from "./types"





function* loginRequest(action) {
  try {
    const response = yield call(login, action.payload)

    if (response?.response?.data?.status === 409) {
      yield put(
        loginActions.loginFailure({
          status: 409,
          message: response.response.data.message
        })
      )
    } else if (response?.response?.data?.status === 401) {
      yield put(
        loginActions.loginFailure({
          status: 401,
          message: response.response.data.message
        })
      )
    } else if (response?.status === 200) {
      yield put(loginActions.loginSuccess(response.data.data))
    } else if (response?.status === 204) {
      yield put(
        loginActions.loginFailure({
          status: 204,
          message: "Invalid Credentials"
        })
      )
    } else if (response?.status === 500) {
      yield put(
        loginActions.loginFailure({
          status: 500,
          message: "Internal Server Error"
        })
      )
    } else if (response?.status === 404) {
      yield put(
        loginActions.loginFailure({
          status: 404,
          message: "Not Found"
        })
      )
    } else {
      yield put(
        loginActions.loginFailure({
          message: "Something went wrong"
        })
      )
    }
  } catch (err) {
    yield put(loginActions.loginFailure(err))
  }
}

function* loginWithAzure (action) {

console.log(action)
  // const navigate = useNavigate()
  try {
    
    console.log(action)
    const response = yield call(LogintoAzure);
      const backendResponse = yield call(postAzureLogin, response)

      console.log("backendResponse",backendResponse);
      if (backendResponse?.response?.data?.status === 409) {
        yield put(
          loginActions.azureSsoFailure({
            status: 409,
            message: backendResponse.data.message
          })
        )
      } else if (backendResponse?.response?.data?.status === 401) {
        yield put(
          loginActions.azureSsoFailure({
            status: 401,
            message: "User not found with this email address"
          })
        )
      } else if (backendResponse?.data?.status === 200) {
        yield put(loginActions.azureSsoSuccess(backendResponse.data.data))
      } else if (backendResponse?.response?.data?.status === 204) {
        yield put(
          loginActions.azureSsoFailure({
            status: 204,
            message: "Invalid Credentials"
          })
        )
      } else if (backendResponse?.response?.data?.status === 500) {
        yield put(
          loginActions.azureSsoFailure({
            status: 500,
            message: "Internal Server Error"
          })
        )
      } else if (backendResponse?.response?.status === 404) {
        yield put(
          loginActions.azureSsoFailure({
            status: 404,
            message: "Not Found"
          })
        )
      } else {
        console.log("in else")
        yield put(
          loginActions.loginFailure({
            message: "Something went wrong"
          })
        )
      }

      

      if (backendResponse?.data?.data.accessToken) {
        action.payload.navigate("/dashboard")
      } else if (backendResponse) {
        console.log(backendResponse)
        yield put(
          loginActions.loginFailure({
            message: "Something went wrong"
          })
        )

      
      }
    // }
  } catch (error) {
    console.log(error)
    yield put(loginActions.azureSsoFailure(error))
  }
}

function* loginSaga() {
  yield all([
    takeEvery(LOGIN_TYPES.REQUEST, loginRequest),
    takeEvery(LOGIN_TYPES.AZURE_SSO_REQUEST, loginWithAzure)
  ])
}

export default loginSaga
